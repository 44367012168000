import React from 'react';
import s from '../ProductItem.scss';
import type {IProduct} from '../../../../types/galleryTypes';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {shouldRenderOutOfStock, shouldRenderPrice} from '../productItemUtils';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';
import {Dialog} from 'wix-ui-tpa/cssVars';
import {ProductType} from '../../../../types/galleryTypes';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';

export interface ITaxDisclaimerProps extends IGalleryGlobalProps {
  product: IProduct;
}

class TaxDisclaimer extends React.Component<ITaxDisclaimerProps> {
  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {product, globals} = this.props;
    const {sendClickShippingInfoLinkSf} = globals;
    sendClickShippingInfoLinkSf(product.id);
  };

  private readonly getPriceBreakdown = () => {
    const {
      globals: {
        priceBreakdown: {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer},
        isRTL,
      },
      product,
    } = this.props;

    if (shouldRenderTaxDisclaimer || shippingDisclaimer?.show) {
      return (
        <PriceBreakdown
          shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
          taxDisclaimerLabel={taxDisclaimer}
          shippingDisclaimer={shippingDisclaimer}
          isDigitalProduct={product.productType === ProductType.DIGITAL}
          whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
          isRTL={isRTL}
          className={s.priceBreakdown}
          DialogComponent={Dialog}
        />
      );
    }
    return null;
  };

  public render() {
    const {
      product,
      globals: {
        allowFreeProducts,
        experiments: {shouldUseCommonDiscountPricingMethods},
      },
    } = this.props;

    const shouldRenderOutOfStockLayout = shouldRenderOutOfStock(product);
    const shouldRenderPriceLayout = shouldRenderPrice(
      product,
      allowFreeProducts,
      shouldUseCommonDiscountPricingMethods
    );

    if (shouldRenderOutOfStockLayout) {
      return <ConditionalRender by={'showAddToCartButton'}>{this.getPriceBreakdown()}</ConditionalRender>;
    } else if (shouldRenderPriceLayout) {
      return this.getPriceBreakdown();
    }
    return null;
  }
}

export const ProductTaxDisclaimer = withGlobals(withTranslations()(TaxDisclaimer));
